import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import If from './If';
import { observer } from 'mobx-react';


const ConfirmationModal = observer(({ onClose, onConfirm, loading, heading }) => {
    return (
        <>
            <ModalTransition>
                <Modal
                    actions={[
                        { text: 'Unlink', onClick: onConfirm, isDisabled: loading },
                        { text: 'Close', onClick: onClose, isDisabled: loading },
                    ]}
                    onClose={onClose}
                    heading={heading}
                    scrollBehavior="inside"
                    height={180}
                    width={800}
                >
                    <If condition={loading}>
                        <div className="flex justify-center">
                            <Spinner />
                        </div>
                    </If>
                </Modal>
            </ModalTransition>
        </>

    );
});

export default ConfirmationModal;