import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import BitbucketBranchesIcon from '@atlaskit/icon/glyph/bitbucket/branches';
import OpenIcon from '@atlaskit/icon/glyph/open';
import Textfield from '@atlaskit/textfield';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import Form, { Field } from '@atlaskit/form';
import Button, { IconButton } from '@atlaskit/button/new';
import useModel from '../hooks/useModel';
import useTestomatioFetch from '../hooks/useTestomatioFetch';
import { useStore } from '../store';
import useRevision from '../hooks/useRevision';

const Branches = observer(({ canCreate, onChange }) => {
  useModel('project');
  const { jira } = useStore();
  const [branches, setBranches] = useState(null);
  const [recentBranches, setRecentBranches] = useState(null);
  const [revision, reloadModel] = useRevision();

  const fetchedBranches = useTestomatioFetch(
    '/branches?state=active',
    {
      method: 'GET',
      prefix: true,
      asHash: true,
    },
    [revision],
  );

  useEffect(() => {
    if (!fetchedBranches.response) return;
    if (!jira.branches) return;
    setBranches(fetchedBranches.response);
    const newSet = new Set(fetchedBranches.response.map(item => item.id));
    const newRecentBrances = jira.branches.filter(branch => {
      return newSet.has(branch.id);
    });
    setRecentBranches(newRecentBrances);
  }, [fetchedBranches.response, jira.branches, revision]);

  const switchBranch = useCallback(
    async (branch) => {
      await jira.setBranch(branch);
      reloadModel();
      if (onChange) onChange();
    },
    [jira, reloadModel, onChange],
  );

  const createBranch = useCallback(
    async (data, form) => {
      const body = {
        type: 'branches',
        attributes: {
          title: data.branch,
        },
      };
      const resp = await jira.testomatioRequest('/branches', {
        method: 'POST',
        success: `Branch ${data.branch} has been created`,
        body: JSON.stringify({ data: body }),
      });
      if (resp && resp.data) {
        reloadModel();
        form.reset();
        switchBranch({ id: resp.data.id, title: resp.data.attributes.title });
      }
    },
    [jira, reloadModel, switchBranch],
  );

  if (!jira.testomatioProject) return <></>;

  return (
    <div>
      {branches && (
        <DropdownMenu
          trigger={
            ({ triggerRef, ...props }) => {
              if (jira.branch) {
                return <Button iconBefore={(iconProps) => <BitbucketBranchesIcon {...iconProps} size="small" />} appearance="primary" label="branches" ref={triggerRef} {...props}>{jira.branch.title}</Button>;
              }
              return <IconButton icon={(iconProps) => <BitbucketBranchesIcon {...iconProps} size="small" />} appearance="default" label="branches" ref={triggerRef} {...props} />;
            }
          }
          position="bottom right"
        >
          <DropdownItemGroup>
            {!jira.branch && canCreate && (
              <DropdownItem key="new-branch" isDisabled>
                <Form onSubmit={createBranch}>
                  {({ formProps }) => (
                    <form {...formProps}>
                      <Field
                        name="branch"
                        label="Create a new branch"
                        defaultValue={() => {
                          if (revision) return;
                          const defaultVal = `${jira.jiraId}`;
                          if (
                            !branches.filter((b) => b.title === defaultVal)
                              .length
                          ) return defaultVal;
                        }}
                      >
                        {({ fieldProps }) => (
                          <Textfield
                            placeholder="Create a new branch"
                            {...fieldProps}
                          />
                        )}
                      </Field>
                    </form>
                  )}
                </Form>
              </DropdownItem>
            )}
            {jira.branch && (
              <DropdownItem
                elemBefore={<SelectClearIcon size="small" />}
                onClick={() => switchBranch()}
              >
                Switch To Main
              </DropdownItem>
            )}
            {jira.branch && (
              <DropdownItem
                elemBefore={<OpenIcon size="small" />}
                href={`${jira.testomatioURL}/projects/${jira.testomatioProject.slug}/branches/${jira.branch.id}`}
                target="_blank"
              >
                Open Branch
              </DropdownItem>
            )}
          </DropdownItemGroup>
          <hr />
          {recentBranches && recentBranches.length > 0 && (
            <DropdownItemGroup title="Related Branches">
              {recentBranches.map((b) => (
                <DropdownItem
                  onClick={() => switchBranch(b)}
                  key={`branch-${b.id}`}
                >
                  {b.title}
                </DropdownItem>
              ))}
            </DropdownItemGroup>
          )}
          <DropdownItemGroup title="All Branches">
            {branches.map((b) => (
              <DropdownItem
                isSelected={jira.branch && b.id === jira.branch.id}
                onClick={() => switchBranch(b)}
                key={`branch-${b.id}`}
              >
                {b.title}
              </DropdownItem>
            ))}
          </DropdownItemGroup>
        </DropdownMenu>
      )}
    </div>
  );
});

export default Branches;
