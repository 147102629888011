/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

// hooks

// components
import DynamicTable from '@atlaskit/dynamic-table';
import AddIcon from '@atlaskit/icon/glyph/add';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import PresenceUnavailableIcon from '@atlaskit/icon/glyph/presence-unavailable';
import { AsyncSelect } from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button/new';
import Badge from '@atlaskit/badge';
import Project from './Project';
import { createEpicViewHead } from '../service/tableService';
import useRevision from '../hooks/useRevision';
import { useStore } from '../store';
import IfLoggedIn from './IfLoggedIn';
import User from './User';

const EpicView = observer(() => {
  const store = useStore();
  const { jira } = store;
  const [loading, setLoading] = useState(true);
  const [childIssues, setChildIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [linkIssue, setLinkIssue] = useState(null);
  const [loadedTests, setLoadedTests] = useState([]);
  const [revision, reloadModel] = useRevision();

  const fetchedSuites = useCallback(async (inputValue) => {
    const { data } = await jira.testomatioRequest(`/suites?file_type=file&query=${inputValue}`, {
      method: 'GET',
    });
    if (!data) return;
    const suiteItems = await data.map((suiteData) => {
      return { label: suiteData.attributes['public-title'], value: suiteData.id };
    });
    return suiteItems;
  }, [jira]);

  useEffect(() => {
    if (!jira.jiraId) return;
    setLoading(true);
    jira
      .getChildIssues(jira.jiraId)
      .then(async (issues) => {
        const tests = await Promise.all(
          issues.map((i) => jira.getAssociatedTests(i.id)),
        );
        return issues.map((issue, index) => {
          return { ...issue, tests: tests[index] };
        });
      })
      .then(setChildIssues)
      .then(() => setLoading(false));
    // jira.getTestDetailForIssue(jira.currentIssue).then(setEpicTests);
    // eslint-disable-next-line
  }, [jira.jiraId, revision]);

  const showIssue = useCallback(
    async (issue) => {
      if (!issue) {
        reloadModel();
        setLoadedTests([]);
        return setSelectedIssue(null);
      }
      const tests = await jira.getTestDetailForIssue(issue.id);
      issue.tests = tests;
      setSelectedIssue(issue);
    },
    [jira, reloadModel],
  );

  const linkToIssue = useCallback(
    async (issue) => {
      if (!issue) {
        reloadModel();
        setLoadedTests([]);
        return setLinkIssue(null);
      }
      const tests = await jira.getTestDetailForIssue(issue.id);
      issue.tests = tests;
      setLinkIssue(issue);
    },
    [jira, reloadModel],
  );

  const searchText = useCallback(
    async (text, state = undefined) => {
      if (text.length > 3) {
        const stateQuery = state ? `state=${state}` : '';
        const textQuery = text !== '' ? `query=${text}` : '';
        const response = await jira.testomatioRequest(
          `/tests?${textQuery}&${stateQuery}`,
          { method: 'GET', asHash: true },
        );
        const tests = response.data.map((item) => {
          return { id: item.id, ...item.attributes };
        });
        setLoadedTests(tests);
      }
    },
    [jira],
  );

  const selectSuite = useCallback(
    async (suiteId) => {
      if (!suiteId) return setLoadedTests([]);
      const response = await jira.testomatioRequest(
        `/tests?suite_id=${suiteId}`,
        { method: 'GET', asHash: true },
      );
      const tests = response.data.map((item) => {
        return { id: item.id, ...item.attributes };
      });
      setLoadedTests(tests);
      // eslint-disable-next-line
    },
    [jira],
  );

  const createEpicViewRows = (issues, showMoreHandle) => {
    return issues.map((issue) => {
      return {
        key: `row-${issue.id}`,
        cells: [
          {
            key: 'title',
            content: (
              <div className="flex items-center text-sm">
                <img
                  src={issue.fields.issuetype.iconUrl}
                  alt={issue.fields.issuetype.name}
                  className="w-4 h-4 mr-2"
                />
                <button
                  type="button"
                  className="text-blue-600 focus:outline-none"
                  onClick={() => jira.getNavigateToIssuePage(issue.key)}
                >
                  <b className="font-bold pr-4">{issue.key}</b>
                  {' '}
                  {issue.fields.summary}
                </button>
              </div>
            ),
          },
          {
            key: 'tests',
            content: (
              <button
                type="button"
                className="text-blue-600 focus:outline-none"
                onClick={() => showMoreHandle(issue)}
              >
                {issue.tests.length}
                {' '}
                tests
              </button>
            ),
          },
          {
            key: 'status',
            content: (
              <div className="uppercase text-xs">
                {issue.fields.status.name}
              </div>
            ),
          },
          {
            key: 'action',
            content: (
              <IfLoggedIn showDisabled>
                <button
                  type="button"
                  className="focus:outline-none"
                  onClick={() => linkToIssue(issue)}
                >
                  <AddIcon size="small" />
                </button>
              </IfLoggedIn>
            ),
          },
        ],
      };
    });
  };

  return (
    <div>
      <div className="mt-2">
        <div className="flex mb-2 w-full items-center justify-between">
          <h3>
            Child Issues with Tests
            {' '}
            {childIssues.length ? <Badge>{childIssues.length}</Badge> : ''}
          </h3>
          <div className="flex">
            <Project onChange={reloadModel} allProjectsDisabled />
          </div>

          <div className="mr-2">
            {' '}
            <User />
          </div>
        </div>
      </div>

      <DynamicTable
        head={createEpicViewHead()}
        emptyView={<div className="empty">No Data</div>}
        rows={createEpicViewRows(
          childIssues,
          (issue) => showIssue(issue),
          (key) => linkToIssue(key),
        )}
        rowsPerPage={childIssues.length > 10 ? 10 : undefined}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        isFixedSize
        defaultSortKey="title"
        defaultSortOrder="ASC"
      />
      <ModalTransition>
        {selectedIssue && (
          <Modal
            onClose={() => showIssue(null)}
            heading={`Tests for ${selectedIssue.fields.summary}`}
            scrollBehavior="inside"
            height={600}
            width={800}
          >
            <div>
              {!selectedIssue.tests.length && (
                <div className="empty">No tests linked</div>
              )}
              {selectedIssue.tests.map((test) => (
                <div className="flex justify my-2 items-center">
                  <TestItem test={test} issue={selectedIssue} isLinked />
                </div>
              ))}
            </div>
          </Modal>
        )}

        {linkIssue && (
          <Modal
            onClose={() => linkToIssue(null)}
            scrollBehavior="inside"
            height={600}
            width={800}
          >
            <div className="flex justify-center items-center mt-2">
              <AsyncSelect
                className="w-full single-select mr-1"
                classNamePrefix="react-select"
                loadOptions={fetchedSuites}
                placeholder="Start typing title of suite"
                onChange={(item) => {
                  if (item) return selectSuite(item.value);
                  selectSuite(null);
                }}
                isClearable
                cacheOptions
                defaultOptions
                maxMenuHeight={250}
              />
              <span className="mr-1 text-sm">(or)</span>
              <TextField
                name="title"
                label="Title"
                placeholder="Search a test"
                className="w-1/2 ml-1"
                onChange={(e) => searchText(e.target.value)} // searchText(e.target.value)}
              />
            </div>
            {!loadedTests.length && (
              <div className="empty">No tests linked</div>
            )}

            <div className="mt-4">
              {loadedTests.length > 0
                && loadedTests.map((test) => (
                  <TestItem test={test} issue={linkIssue} />
                ))}
              {loadedTests.length < 1
                && linkIssue.tests
                  .filter((test) => {
                    const filteredIssue = childIssues.filter(
                      (i) => i.key === linkIssue.id,
                    )[0];
                    if (!filteredIssue) return false;
                    return !filteredIssue.tests
                      .map((t) => t.id)
                      .includes(test.id);
                  })
                  .map((test) => <TestItem test={test} issue={linkIssue} />)}
            </div>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
});

export default EpicView;

const TestItem = observer(({ test, issue, isLinked }) => {
  const [linked, setLinked] = useState(
    (test['jira-issues'] && test['jira-issues'].map(item => item.jira_id).includes(issue.key)) || isLinked,
  );
  const [loading, setLoading] = useState(false);

  const store = useStore();
  const { jira } = store;

  const linkTest = useCallback(async () => {
    setLoading(true);
    const response = await jira.testomatioRequest('/jira/issues', {
      method: 'POST',
      body: JSON.stringify({
        jira_id: issue.key,
        test_id: test.id,
      }),
    });
    if (response) setLinked(true);
    setLoading(false);
    // eslint-disable-next-line
  }, [issue]);

  const unlinkTest = useCallback(async () => {
    setLoading(true);
    const response = await jira.testomatioRequest(`/jira/issues/${issue.key}`, {
      method: 'DELETE',
      body: JSON.stringify({ test_id: test.id }),
    });
    if (response) setLinked(false);
    setLoading(false);
    // eslint-disable-next-line
  }, [issue]);

  useEffect(() => {
    setLinked(
      (test['jira-issues'] && test['jira-issues'].map(item => item.jira_id).includes(issue.key))
      || isLinked,
    );
  }, [test, issue, isLinked]);

  return (
    <div className="flex w-full space-x-2 mb-1 hover:bg-gray-100 px-1 items-center justify-between">
      <PresenceUnavailableIcon size="small" />
      <a
        className="ml-2 flex-1 text-blue-600"
        href={`${jira.projectUrl}/test/${test.id}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {test.title}
      </a>
      {' '}
      {linked ? (
        <Button
          size="small"
          appearance="default"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => unlinkTest()}
          isLoading={loading}
        >
          Unlink
        </Button>
      ) : (
        <Button
          size="small"
          appearance="primary"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => linkTest()}
          isLoading={loading}
        >
          Link
        </Button>
      )}
    </div>
  );
});

TestItem.propTypes = {
  test: PropTypes.any.isRequired,
  jiraId: PropTypes.string.isRequired,
  testomatioURL: PropTypes.string.isRequired,
  isLinked: PropTypes.bool,
};

TestItem.defaultProps = {
  isLinked: false,
};
