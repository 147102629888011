import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Select, { AsyncSelect } from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import PropTypes from 'prop-types';
import PresenceUnavailableIcon from '@atlaskit/icon/glyph/presence-unavailable';
import Button from '@atlaskit/button/new';
import Modal, { ModalFooter } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { useStore } from '../store';
import If from './If';

const LinkTests = observer(({ onClose }) => {
  const store = useStore();
  const { jira, user } = store;
  const [matchedTests, setMatchedTests] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');
  const [linkedTests, setLinkedTests] = useState([]);
  const [unlinkedTests, setUnlinkedTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [intermediateTests, setIntermediateTests] = useState([]);
  const [tags, setTags] = useState([]);
  const fetchedSuites = useCallback(async (inputValue) => {
    const { data } = await jira.testomatioRequest(`/suites?file_type=file&query=${inputValue}`, {
      method: 'GET',
    });
    if (!data) return;
    const suiteItems = await data.map((suiteData) => {
      return { label: suiteData.attributes['public-title'], value: suiteData.id };
    });
    //fetch tags
    const resTags = await jira.testomatioRequest('/tags', {
      method: 'GET',
    });
    console.log('tagsData', resTags.data);
    setTags(resTags.data);
    return suiteItems;
  }, [jira]);

  // const fetchedTags = useCallback(async () => {
  //   const { data } = await jira.testomatioRequest('/tags', {
  //     method: 'GET',
  //   });
  //   setTags(data);
  // }, [jira]);

  const morePlans = async (num) => {
    let items = [];
    // eslint-disable-next-line
    for (let i = 2; i <= num; i++) {
      // eslint-disable-next-line
      const planList = await jira.testomatioRequest(`/plans?page=${i}`, {
        method: 'GET',
      });
      if (planList.data) {
        items = [...items, ...planList.data];
      }
    }
    return items.map((plan) => {
      return { label: plan.attributes.title, value: plan.id };
    });
  };

  const fetchedPlans = useCallback(async () => {
    let items = [];
    const planList = await jira.testomatioRequest('/plans', {
      method: 'GET',
    });
    if (planList.data) {
      items = planList.data.map((plan) => {
        return { label: plan.attributes.title, value: plan.id };
      });
    }
    if (planList.meta.total_pages > 1) {
      const more = await morePlans(planList.meta.total_pages);
      items = [...items, ...more];
    }
    setPlans(items);
    setLoader(false);
    // eslint-disable-next-line
  }, [plans]);

  useEffect(() => {
    fetchedPlans();
    // eslint-disable-next-line
  }, []);

  const selectSuite = useCallback(async (suiteId) => {
    setMatchedTests([]);
    setSearchTxt('');
    setLoading(true);
    const response = await jira.testomatioRequest(
      `/tests?suite_id=${suiteId}&list=true&issues=true`,
      { method: 'GET' },
    );
    const foundTests = (response.data || []).map((item) => {
      return { id: item.id, ...item.attributes, branch: item.relationships?.branch?.data?.id };
    });
    setLoading(false);
    setMatchedTests(foundTests);
    // eslint-disable-next-line
  }, []);

  const addIssuesArray = useCallback(async (matchedItems) => {
    if (matchedItems.length === 0) return [];
    const addIssues = [];
    // eslint-disable-next-line
    for (const item of matchedItems) {
      // eslint-disable-next-line
      const resp = await jira.testomatioRequest(`/tests/${item.id}`, { method: 'GET' });
      item['jira-issues'] = resp.data.attributes['jira-issues'];
      addIssues.push(item);
    }
    return addIssues;
  }, [jira]);

  const searchText = useCallback(async () => {
    setMatchedTests([]);
    setLoading(true);
    if (!searchTxt.startsWith('@')) {
      const query = searchTxt !== '' ? `query=${searchTxt}` : '';
      const response = await jira.testomatioRequest(`/tests?${query}`, {
        method: 'GET',
      });
      const foundTests = (response.data || []).map((item) => {
        return { id: item.id, ...item.attributes };
      });
      const addIssues = await addIssuesArray(foundTests);
      setMatchedTests(addIssues);
      setLoading(false);
    } else if (searchTxt.startsWith('@')) {
      const response = await jira.testomatioRequest(`/suites/search?query=${searchTxt}`, {
        method: 'GET',
      });
      const foundTests = (response?.data || []).reduce((acc, item) => {
        return [...acc, ...item.filteredTests];
      }, []);
      const addIssues = await addIssuesArray(foundTests);
      setMatchedTests(addIssues);
      setLoading(false);
    }
  }, [jira, addIssuesArray, searchTxt]);

  const selectPlan = useCallback(async (plan) => {
    setMatchedTests([]);
    setSearchTxt('');
    setLoading(true);
    const response = await jira.testomatioRequest(`/tests?detail=false&plan=${plan}&list=true&issues=true`, {
      method: 'GET',
    });
    const foundedTests = (response.data || []).map((item) => {
      return { id: item.id, ...item.attributes };
    });
    setMatchedTests(foundedTests);
    setLoading(false);
  }, [jira]);

  useEffect(() => {
    const linked = matchedTests.filter(test => test['jira-issues'] && test['jira-issues'].map(issue => issue.jira_id).includes(jira.jiraId) === false);
    const unlinked = matchedTests.filter(test => test['jira-issues'] && test['jira-issues'].map(issue => issue.jira_id).includes(jira.jiraId));
    setLinkedTests(linked);
    setUnlinkedTests(unlinked);
  }, [matchedTests, loading, jira.jiraId]);

  const selectSuiteAdv = useCallback(async (suiteId) => {
    if (searchTxt.length > 0 && intermediateTests.length > 0) {
      const matched = intermediateTests.filter(test => test['suite-id'] === suiteId);
      setIntermediateTests(matched);
      setMatchedTests(matched);
    } else {
      setLoading(true);
      const response = await jira.testomatioRequest(
        `/tests?suite_id=${suiteId}&list=true&issues=true`,
        { method: 'GET' },
      );
      const foundTests = (response.data || []).map((item) => {
        return { id: item.id, ...item.attributes, branch: item.relationships?.branch?.data?.id };
      });
      setIntermediateTests(foundTests);
      setMatchedTests(foundTests);
      setLoading(false);
    }
  }, [jira, searchTxt, intermediateTests]);

  const searchTextAdv = useCallback(async () => {
    if (intermediateTests.length > 0) {
      if (searchTxt.startsWith('@')) {
        const tagText = searchTxt.slice(1, searchTxt.length);
        if (searchTxt.startsWith('@T') && searchTxt.length === 10) {
          const ide = searchTxt.slice(2, searchTxt.length);
          setMatchedTests(intermediateTests.filter(test => test.id === ide) || intermediateTests);
        } else {
          const matched = intermediateTests.filter(test => test.tags.includes(tagText));
          setMatchedTests(matched || intermediateTests);
        }
      } else {
        const matched = intermediateTests.filter(test => test.title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1);
        setMatchedTests(matched || intermediateTests);
      }
    } else if (intermediateTests.length === 0) {
      if (searchTxt.startsWith('@')) {
        setLoading(true);
        const response = await jira.testomatioRequest(`/suites/search?query=${searchTxt}`, {
          method: 'GET',
        });
        const foundTests = (response?.data || []).reduce((acc, item) => {
          return [...acc, ...item.filteredTests];
        }, []);
        const addIssues = await addIssuesArray(foundTests);
        setIntermediateTests(addIssues);
        setMatchedTests(addIssues);
        setLoading(false);
      } else {
        setLoading(true);
        const query = searchTxt !== '' ? `query=${searchTxt}` : '';
        const response = await jira.testomatioRequest(`/tests?${query}`, {
          method: 'GET',
        });
        const foundTests = (response.data || []).map((item) => {
          return { id: item.id, ...item.attributes };
        });
        const addIssues = await addIssuesArray(foundTests);
        setIntermediateTests(addIssues);
        setMatchedTests(addIssues);
        setLoading(false);
      }
    }
  }, [intermediateTests, jira, addIssuesArray, searchTxt]);

  const reloadMatchedTests = useCallback(async () => {
    const update = await addIssuesArray(matchedTests);
    setMatchedTests([]);
    setMatchedTests(update);
  }, [addIssuesArray, matchedTests]);

  const linkAllTests = useCallback(async () => {
    if (!user.isLoggedIn) return;
    setLoading(true);
    // eslint-disable-next-line
    for (let i = 0; i < linkedTests.length; i++) {
      // eslint-disable-next-line
      await jira.testomatioRequest('/jira/issues', {
        method: 'POST',
        body: JSON.stringify({ jira_id: jira.jiraId, test_id: linkedTests[i].id }),
      });
    }
    setLoading(false);
    await reloadMatchedTests();
  }, [linkedTests, reloadMatchedTests, jira, user.isLoggedIn]);

  const unlinkAllTests = useCallback(async () => {
    if (!user.isLoggedIn) return;
    setLoading(true);
    const tests = unlinkedTests.map(item => item.id);
    try {
      await jira.testomatioRequest(`/jira/issues/${jira.jiraId}`, {
        method: 'DELETE',
        prefix: true,
        body: JSON.stringify({ test_ids: tests }),
      });
    } catch (e) {
      //
    } finally {
      setLoading(false);
      await reloadMatchedTests();
    }
  }, [jira, unlinkedTests, reloadMatchedTests, user.isLoggedIn]);

  const clickEnter = (e) => {
    if (e.key === 'Enter') {
      searchText(searchTxt);
    }
  };

  const clickEnterAdv = (e) => {
    if (e.key === 'Enter') {
      searchTextAdv(searchTxt);
    }
  };

  const CustomFooter = () => {
    return (
      <ModalFooter style={{ padding: 12, justifyContent: 'flex-end' }}>
        <div className="space-x-2">
          <Button
            size="small"
            appearance="link"
            spacing="compact"
            onClick={() => {
              setMatchedTests([]);
              setSearchTxt('');
              setIntermediateTests([]);
              setShowAdvanced(!showAdvanced);
            }}
          >
            {showAdvanced ? 'Close advanced' : 'Advanced'}
            {' '}
            filter
          </Button>
          <If condition={linkedTests.length > 0}>
            <Button
              size="small"
              appearance="primary"
              sizes="small"
              spacing="compact"
              onClick={() => linkAllTests()}
              isLoading={loading}
            >
              Link All
            </Button>
          </If>
          <If condition={unlinkedTests.length > 0}>
            <Button
              size="small"
              appearance="default"
              sizes="small"
              spacing="compact"
              onClick={() => unlinkAllTests()}
              isLoading={loading}
            >
              Unlink All
            </Button>
          </If>
        </div>

      </ModalFooter>
    );
  };

  return (
    <Modal
      onClose={onClose}
      scrollBehavior="inside-wide"
      height={600}
      width={800}
      components={{ Footer: CustomFooter }}
    >
      <If condition={!loader}>
        <div className="flex justify-center items-center mt-2">
          <If condition={!showAdvanced}>
            <AsyncSelect
              className="w-full single-select mr-1"
              onChange={(item) => {
                if (item) return selectSuite(item.value);
                setMatchedTests([]);
                setSearchTxt('');
              }}
              isClearable
              cacheOptions
              defaultOptions
              loadOptions={fetchedSuites}
              placeholder="Start typing title of suite"
              maxMenuHeight={220}
            />
            <span className="mr-1 text-sm">(or)</span>
            <Select
              className="w-full single-select mr-1"
              classNamePrefix="react-select"
              options={plans}
              placeholder="Search a plan"
              onChange={(item) => {
                if (item) return selectPlan(item.value);
                setMatchedTests([]);
                setSearchTxt('');
              }}
              isClearable
              maxMenuHeight={220}
            />
            <span className="mr-1 text-sm">(or)</span>
            <TextField
              name="title"
              label="Title"
              placeholder="Title, @tag or id and press 'Enter'"
              className="w-1/2 ml-1"
              value={searchTxt}
              list="title-list"
              onChange={(e) => setSearchTxt(e.target.value)}
              onKeyPress={(e) => clickEnter(e)}
            />
            <datalist id="title-list">
              {(tags || []).map(tag => (
                <option value={`@${tag.id}`} />
              ))}
            </datalist>
          </If>
          <If condition={showAdvanced}>
            <AsyncSelect
              className="w-full single-select"
              onChange={(item) => {
                if (item) return selectSuiteAdv(item.value);
                setMatchedTests([]);
                setIntermediateTests([]);
              }}
              isClearable
              cacheOptions
              defaultOptions
              loadOptions={fetchedSuites}
              placeholder="Start typing title of suite"
              maxMenuHeight={220}
            />
            <span className="mx-1 text-sm">(and)</span>
            <TextField
              name="title"
              label="Title"
              placeholder="Title, @tag or id and press 'Enter'"
              className="w-1/2 ml-1"
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              onKeyPress={(e) => clickEnterAdv(e)}
            />
          </If>
        </div>
        <If condition={matchedTests.length > 0 && !loading}>
          {matchedTests.map((test) => (
            <TestItem test={test} jiraId={jira.jiraId} />
          ))}
        </If>
        <If condition={matchedTests.length === 0 && !loading}>
          <div className="w-full flex justify-center mt-32">No results found</div>
        </If>
        <If condition={loading}>
          <div className="flex justify-center mt-32">
            <Spinner size="large" />
          </div>
        </If>
      </If>
    </Modal>
  );
});

const TestItem = observer(({ test }) => {
  const [linked, setLinked] = useState(null);
  const [loading, setLoading] = useState(false);
  const { jira } = useStore();

  const linkTest = useCallback(async () => {
    setLoading(true);
    const body = {
      jira_id: jira.jiraId,
      test_id: test.id,
    };

    const response = await jira.testomatioRequest('/jira/issues', {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (response) setLinked(true);
    setLoading(false);
  }, [jira, test]);

  const unlinkTest = useCallback(async () => {
    setLoading(true);
    const response = await jira.testomatioRequest(
      `/jira/issues/${jira.jiraId}`,
      { method: 'DELETE', body: JSON.stringify({ test_id: test.id }) },
    );
    if (response) setLinked(false);
    setLoading(false);
  }, [jira, test]);

  useEffect(() => {
    if (!test) return;
    setLinked(test['jira-issues'] && test['jira-issues'].map(issue => issue.jira_id).includes(jira.jiraId));
  }, [test, jira]);

  return (
    <div className="flex justify space-x-2 items-center mb-1">
      <PresenceUnavailableIcon size="small" />
      <span className="pr-1">
        {test['is-branched'] && test.branch && (
          <span
            className="mr-2 inline-block align-middle truncate bg-indigo-700 text-white text-xs px-2"
            style={{ maxWidth: '60px' }}
          >
            {test.branch}
          </span>
        )}

        {test.title}
      </span>
      {' '}
      <If condition={linked}>
        <Button
          size="small"
          appearance="default"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => unlinkTest()}
          isLoading={loading}
        >
          Unlink
        </Button>
      </If>
      <If condition={!linked}>
        <Button
          size="small"
          appearance="primary"
          sizes="small"
          spacing="compact"
          className="ml-2"
          onClick={() => linkTest()}
          isLoading={loading}
        >
          Link
        </Button>
      </If>
    </div>
  );
});

TestItem.propTypes = {
  test: PropTypes.any.isRequired,
  jiraId: PropTypes.string.isRequired,
  testomatioURL: PropTypes.string.isRequired,
};

export default LinkTests;
