import React from 'react';
import { observer } from 'mobx-react';

const MultiselectionIcon = observer(({ isSelected, ...props }) => {
    return (
        isSelected ? (
            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#253858" viewBox="0 0 24 24" {...props}>
                <path d="M22,16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H20A2,2 0 0,1 22,4V16M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16M13,14L20,7L18.59,5.59L13,11.17L9.91,8.09L8.5,9.5L13,14Z" />
            </svg>
        ) : (
            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#42526E" viewBox="0 0 24 24" {...props}>
                <path d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
            </svg>
        )
    );
});

export default MultiselectionIcon;
